<script>
  import MovieItem from "$lib/MovieItem.svelte";
  import Teads from "$lib/Teads.svelte";
  import Adsense from "$lib/Adsense.svelte";
  import {
    recommended_titles,
    recommended_titles_list_index,
    // startup_state,
    all_posters_loaded,
    loading_titles
  } from "$lib/store.js"; // all_posters_loaded for automatically retrieving movie.getDetails:
  import { movieCount } from "$lib/search.js";
  import { onMount, afterUpdate } from "svelte";
  import { _ } from "svelte-i18n";

  let showJumpToTop = false;
  let footer;
  let scrollY = 0;
  let buttonBottom = 8;
  let loadedPosters = 0; // for automatically retrieving movie.getDetails
  let openLists = [];

  $: showJumpToTop = scrollY > 100;

  $: if ($loading_titles == true) {
    openLists = [];
  }

  recommended_titles.subscribe(() => {
    const titleIndex = $recommended_titles.length - 1;
    openLists = [titleIndex];
    // setTimeout(() => {
    //   if (typeof window !== "undefined") {
    //     const currentList = window.document.getElementById("list-" + titleIndex);
    //     const searchDiv = document.querySelector("#search");
    //     if (currentList && searchDiv && !$startup_state) {
    //       const targetPosition = currentList.offsetTop - searchDiv.offsetHeight - 56; // 56 for header height
    //       window.scrollTo({ top: targetPosition, behavior: "smooth" });
    //     }
    //   }
    // }, 0);
  });

  function scrollToTop() {
    window.scrollTo({ top: 1, behavior: "smooth" });
  }

  function handleScroll() {
    scrollY = window.scrollY;
    if (footer) {
      const footerRect = footer.getBoundingClientRect();
      const footerTop = footerRect.top;
      const viewportHeight = window.innerHeight;
      if (footerTop < viewportHeight) {
        buttonBottom = viewportHeight - footerTop + 5;
      } else {
        buttonBottom = 8; // Default position
      }
    }
  }

  // for automatically retrieving movie.getDetails:
  function checkAllPostersLoaded() {
    loadedPosters++;
    if (loadedPosters === $recommended_titles[$recommended_titles_list_index].length - 1) {
      all_posters_loaded.set(true);
    }
  }

  onMount(() => {
    footer = document.getElementById("footer");
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  afterUpdate(() => {
    handleScroll();
  });
</script>

<!-- ---------------------------------------------------------------------- -->

{#each [...$recommended_titles, "extra_list"] as list, list_index}
  <div
    class="movielist-container {list_index == $recommended_titles.length - 1 ? 'current' : ''}"
    id="list-{list_index}">
    {#if $loading_titles && list_index == $recommended_titles.length - 1}
      <div class="loader" />
    {/if}
    <!-- {#if $loading_titles}
      <div class="loader" />
    {/if} -->
    <div
      class="movielist {openLists.includes(list_index) ? '' : 'collapsed'}{list_index == $recommended_titles.length - 1
        ? 'current'
        : ''}"
      id="li-{list_index}">
      <Teads />
      <Adsense />
      {#if $recommended_titles[list_index] && $recommended_titles[list_index] != -1}
        {#each list as item, item_index}
          <MovieItem {item} {item_index} {list_index} on:onLoad={() => checkAllPostersLoaded()} />
        {/each}
      {:else}
        {#if $recommended_titles[list_index] == -1 && !$loading_titles}
          <p>{$_("No matching movies found, sorry.")}</p>
        {/if}
        {#each new Array(movieCount) as placeholder}
          <div class="movie-item-placeholder" key={placeholder} />
        {/each}
      {/if}
    </div>
  </div>
{/each}
<button
  on:click={scrollToTop}
  bottom={buttonBottom}
  class="jump-to-top"
  style="visibility: {showJumpToTop ? 'visible' : 'hidden'}; bottom:{buttonBottom}px;" />

<!-- ---------------------------------------------------------------------- -->

<style>
  .movielist-container {
    width: 90%;
    align-self: center;
    /* margin-top: 48px; */
  }
  .movielist {
    display: grid;
    /* margin-top: 55px; */
    /* margin-bottom: 55px; */
    /* margin-left: 80px; */
    /* margin-right: 80px; */
    padding-left: 33px;
    padding-right: 33px;
    grid-template-rows: repeat(1, minmax(200px, 1fr));
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 48px 52px;
  }
  .collapsed {
    height: 0;
    overflow: hidden;
  }
  .loader {
    background-image: url("/icons/icon_bouncing-circles.svg");
    width: 50px;
    height: 52px;
    align-self: center;
    margin: 0 auto 0 auto;
  }

  @media (max-width: 480px) {
    .movielist-container {
      /* margin-left: 50px; */
      /* margin-right: 50px; */
      padding-left: 18px;
      padding-right: 18px;
      grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    }
  }

  .movie-item-placeholder {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .jump-to-top {
    position: fixed;
    right: 50%;
    height: 48px;
    width: 48px;
    z-index: 100; /* Ensure it's above other content */
    background-image: url("/buttons/btn_up.svg");
    background-color: transparent;
    cursor: pointer;
    padding: 10px;
    border: none;
    transform: translateX(50%);
  }

  p {
    color: var(--lightest-blue);
  }
</style>
