<script>
  import { _, locale } from "svelte-i18n";
  import { PUBLIC_API_BASE_URL, PUBLIC_FRONTEND_VERSION } from "$env/static/public";
  import { onMount } from "svelte";
  import { current_year, service_name } from "$lib/store.js";
  import MarkdownModal from "$lib/MarkdownModal.svelte";

  let source = "# Loading Text ...";
  let isOpen = false;
  let beVersion = "";

  onMount(() => getBackendVersion().then((ver) => (beVersion = ver)));

  function openMarkdownModal(file) {
    fetch("/legal_" + $service_name.toLowerCase() + "/" + $locale + "." + file + ".md")
      .then((response) => response.text())
      .then((text) => {
        source = text;
      });
    isOpen = true;
    document.body.style.overflow = "hidden"; // Disables scrolling on the body when the modal is open
  }

  function closeMarkdownModal() {
    isOpen = false;
    document.body.style.overflow = "";
  }

  async function getBackendVersion() {
    const url = PUBLIC_API_BASE_URL + "/api/info/getVersion";
    const response = await fetch(url);
    try {
      if (!response.ok) {
        return "v0.x.x";
      }
    } catch (err) {
      console.error(err.message);
      return "v0.x.x";
    }
    const json = await response.json();
    return json.data.version;
  }

  function trackDonationFooterBtn() {
    window._paq.push(["trackEvent", "donation-footer-btn", "clicked"]);
  }
</script>

<!-- ---------------------------------------------------------------------- -->

<div id="footer">
  <div class="footer-menu-container">
    <div
      class="footer-menu-item"
      role="button"
      tabindex="0"
      on:click={() => openMarkdownModal("imprint")}
      on:keypress={() => openMarkdownModal("imprint")}>
      {$_("imprint")}
    </div>
    <div
      class="footer-menu-item"
      role="button"
      tabindex="0"
      on:click={() => openMarkdownModal("pp")}
      on:keypress={() => openMarkdownModal("pp")}>
      {$_("privacy-policy")}
    </div>
    <div
      class="footer-menu-item"
      role="button"
      tabindex="0"
      on:click={() => openMarkdownModal("tc")}
      on:keypress={() => openMarkdownModal("tc")}>
      {$_("terms-and-conditions")}
    </div>
    <div
      class="footer-menu-item"
      role="button"
      tabindex="0"
      on:click={() => openMarkdownModal("about")}
      on:keypress={() => openMarkdownModal("about")}>
      {$_("about us")}
    </div>
    <div
      class="footer-menu-item"
      role="button"
      tabindex="0"
      on:click={() => openMarkdownModal("faq")}
      on:keypress={() => openMarkdownModal("faq")}>
      {$_("faq")}
    </div>
  </div>
  <div class="supported-by">{$_("supported-by")}:</div>
  <div class="footer-sponsors-container">
    <div class="supported-logo">
      <img src="/logos/ktbe_logo.png" alt="KtBE" width="92px" height="72px" />
    </div>
    <div class="supported-logo">
      <img src="/logos/bak_logo.png" alt="BAK" width="233px" height="84px" />
    </div>
    <div class="supported-logo">
      <img src="/logos/egs_logo.png" alt="EGS" width="152px" height="38px" />
    </div>
    <div class="supported-logo">
      <img src="/logos/suissimage_logo.png" alt="Suissimage" width="129px" height="26px" />
    </div>
    <div class="supported-logo">
      <img src="/logos/swissperform-logo-white.svg" alt="Swissperform" width="110px" height="50px" />
    </div>
    <div class="supported-logo">
      <a
        href="https://www.paypal.com/donate/?business=VCQTYXPW42KUL&no_recurring=0&item_name={$_(
          'paypal_button_text'
        )}&currency_code=CHF"
        rel="noopener noreferrer"
        on:click={() => trackDonationFooterBtn()}
        target="_blank">
        <img class="donate-btn" alt="DONATE" width="240px" height="51px" />
      </a>
    </div>
  </div>
  <div>
    © {current_year}
    {$service_name + $_("footer-copyright")}
  </div>
  <div>
    Version: {PUBLIC_FRONTEND_VERSION} (frontend) | {beVersion} (backend)
  </div>
</div>

<MarkdownModal {source} {isOpen} on:close={closeMarkdownModal} />

<!-- ---------------------------------------------------------------------- -->

<style>
  #footer {
    position: relative;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: var(--true-black);
    color: var(--solid-grey);
    text-align: center;
    font-size: 13px;
    padding-bottom: 20px;
    margin-top: 62px;
    width: 100%;
  }
  .footer-menu-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 13px;
  }
  .footer-menu-item {
    margin-right: 24px;
    margin-left: 24px;
    margin-bottom: 12px;
    cursor: pointer;
  }
  .footer-menu-item:hover {
    color: var(--white);
  }
  .supported-by {
    margin-top: 60px;
    color: var(--white);
  }
  .footer-sponsors-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 0px;
    margin-bottom: 40px;
  }
  .supported-logo {
    margin: 20px;
  }
  .supported-logo img {
    max-height: 100%;
    max-width: 100%;
  }
  .donate-btn {
    content: url("/buttons/btn_donate.png");
  }
  .donate-btn:hover {
    content: url("/buttons/btn_donate_hover.png");
    cursor: pointer;
  }
</style>
