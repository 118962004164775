<script>
  export let item;
  export let list_index;
  export let item_index;

  import { locale } from "svelte-i18n";
  import {
    keywords_store,
    recommended_titles_item_index,
    recommended_titles_list_index,
    movie_overlay_is_open
  } from "$lib/store.js";
  import { onDestroy, createEventDispatcher } from "svelte";
  import { pushState } from "$app/navigation";
  import { onMount } from "svelte";
  import MovieDetail from "$lib/MovieDetail.svelte";

  let isOpen = false;
  let titleID;
  let noPoster;
  let h2;
  let movieOverlay;
  // let overlayIsOpen;

  $: smartDevice =
    /Mobi|Android|iPhone/i.test(navigator.userAgent) || /iPad|Tablet|Android(?!.*Mobile)/i.test(navigator.userAgent);

  const unsubscribe = recommended_titles_item_index.subscribe(($recommended_titles_item_index) => {
    if (list_index == $recommended_titles_list_index && item_index == $recommended_titles_item_index && !isOpen) {
      openDetailModal();
    }
  });

  async function openDetailModal() {
    isOpen = true;
    updateUrl(titleID);
    trackClick();
  }

  function closeDetailModal() {
    isOpen = false;
    removeTitleIDfromURL();
    document.body.style.overflow = "";
  }

  function updateUrl(titleID) {
    const currentUrl = new URL(window.location.href);
    currentUrl.searchParams.set("id", titleID);
    currentUrl.searchParams.set("title", item.title);
    pushState(currentUrl.pathname + currentUrl.search);
  }

  function removeTitleIDfromURL() {
    const currentUrl = new URL(window.location.href);
    currentUrl.searchParams.delete("id");
    currentUrl.searchParams.delete("title");
    pushState(currentUrl.pathname + currentUrl.search);
  }

  function setTitlesIndex() {
    recommended_titles_list_index.set(list_index);
    recommended_titles_item_index.set(item_index);
  }

  function getPosterImageUrl(item_json) {
    let poster = item_json.image_urls.find((i) => i.image_type === "POSTER");
    if (poster === null || poster === undefined || poster === "") {
      noPoster = true;
      return "/no_poster.svg";
    } else {
      let url = poster.image_url.replace(/(\.jpg|\.png)/g, ".webp");
      return url;
    }
  }

  function trackClick() {
    const KeywordTextValues = $keywords_store.map((item) => item.displayText);
    const joinedKeywordTextValues = KeywordTextValues.join(", ");
    const KeywordIDs = $keywords_store.map((item) => item.id);
    const joinedKeywordIDs = KeywordIDs.join(", ");
    window._paq.push([
      "trackEvent",
      "movie",
      "opened",
      `movie-name: ${item.title}; search-keywords: ${joinedKeywordTextValues}; movie-id: ${item.mowizz_id}; locale: ${$locale}; Keyword-ids: ${joinedKeywordIDs}}`
    ]);
  }

  // for automatically retrieving movie.getDetails:
  const dispatch = createEventDispatcher();
  function handlePosterLoad() {
    dispatch("onLoad");
  }

  function toggleOverlay() {
    movie_overlay_is_open.set(!$movie_overlay_is_open);
    // overlayIsOpen = !overlayIsOpen;
  }
  onMount(() => {
    titleID = item.mowizz_id;
  });

  onDestroy(() => {
    unsubscribe();
  });

  // Add the next line to the img-tag for automatically retrieving movie.getDetails:
  // on:load={handlePosterLoad}
</script>

<!-- ---------------------------------------------------------------------- -->

<div class="movie-item {!smartDevice ? 'desktop ' : ''}">
  <div class="poster-button" role="button" tabindex="0" on:click={setTitlesIndex} on:keypress={() => setTitlesIndex()}>
    <img
      src={getPosterImageUrl(item)}
      class="title-poster"
      alt={item.title}
      width="4"
      height="6"
      on:load={handlePosterLoad()} />
  </div>
  <div
    bind:this={movieOverlay}
    class="movie-overlay {noPoster ? 'no-poster ' : ''}{smartDevice ? 'mobile ' : ''}{$movie_overlay_is_open
      ? 'open'
      : ''}"
    style="--y-offset: {h2 && movieOverlay ? movieOverlay.clientHeight - h2.scrollHeight - 44 + 'px' : ''};">
    {#if smartDevice}
      <button class="movie-overlay-button" tabindex="0" on:click={toggleOverlay}></button>
    {/if}
    <h2 bind:this={h2}>{item.title}</h2>
    <div class="all-keywords">
      {#each item.suggested_tags as keyword, index}
        {keyword.displayText}
        {#if index < item.suggested_tags.length - 1}
          <span> • </span>
        {/if}
      {/each}
    </div>
    <p>
      {item.release_year}
      {#if item.production_countries && item.production_countries != "NaN" && item.production_countries.length > 0}
        • {item.production_countries.join(", ")}
      {/if}
    </p>
  </div>
</div>

<!-- FIXME: backdropImageUrl should be passed as response to getDetails() call> -->
<!-- see https://github.com/doklab/mowizz-search/issues/174 -->
{#if item}
  <MovieDetail {titleID} {item_index} {isOpen} on:close={closeDetailModal} />
{/if}

<!-- ---------------------------------------------------------------------- -->

<style>
  .movie-item {
    position: relative;
    text-align: center;
    cursor: pointer;
    width: 100%;
    height: 100%;
    overflow: hidden;
    transition: all 0.3s;
    z-index: 10;
    border-radius: 0.5rem;
  }
  .movie-item:hover {
    width: 110%;
    height: 110%;
    margin-top: -5%;
    margin-left: -5%;
    margin-bottom: -10%;
    z-index: 11;
  }
  .poster-button {
    display: flex;
  }
  .title-poster {
    width: 100%;
    height: auto;
    display: inline-block;
    color: var(--solid-red);
    font-size: 1.5rem;
    text-align: left;
    background-color: var(--dark-grey);
    border-radius: 0.5rem;
  }
  .movie-item:hover .title-poster {
    width: 100%;
  }
  .movie-overlay {
    position: absolute;
    bottom: 0;
    transform: translateY(100%);
    max-width: 100%;
    color: var(--white);
    background-color: var(--black-80);
    border-radius: 12px 12px 0 0;
    padding: 5px 20px 6%;
    pointer-events: none;
    transition: all 0.3s;
  }
  .movie-overlay.mobile {
    border-radius: 0;
  }
  .movie-overlay-button {
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 32px;
    border: 1px transparent;
    color: var(--icon-light-blue);
    background-image: url("/icons/icon_dots.svg");
    background-size: 32px;
    background-repeat: no-repeat;
    background-position: 50% 8px;
    background-color: var(--dark-grey);
    margin-top: -41px;
    text-align: center;
    pointer-events: auto;
  }
  .movie-overlay.no-poster {
    transform: translateY(var(--y-offset));
  }
  .movie-item.desktop:hover .movie-overlay,
  .movie-overlay.open {
    transform: translateY(0);
  }
  .all-keywords {
    opacity: 1;
  }
  /* .movie-item:hover .all-keywords {
    opacity: 1;
  } */

  /* .keyword {
    z-index: 2;
    display: block;
    cursor: pointer;
    position: absolute;
    background: var(--white-grey);
    color: var(--solid-grey);
    padding: 0.5rem 1rem;
    border-radius: 0.75rem;
    font-size: 1rem;
    margin: 5px;
    text-align: center;
    transform: translate(-50%, -50%);
    word-wrap: break-word;
    max-width: 9rem;
    inline-size: max-content;
    hyphens: auto;
    opacity: 0;
    border: 1px solid transparent;
  }

  .keyword:hover {
    cursor: pointer;
    background-color: var(--solid-blue);
    color: var(--white);
    border: 1px solid var(--white);
  }

  .movie-item:hover .keyword {
    opacity: 1;
    transition:
      opacity 0.3s,
      transform 0.3s,
      left 0.3s,
      top 0.3s;
  }

  .keyword0 {
    top: 24%;
    left: 20%;
  }

  .movie-item:hover .keyword0 {
    top: 20%;
    left: 12%;
  }

  .keyword1 {
    top: 50%;
    left: 8%;
  }

  .movie-item:hover .keyword1 {
    top: 50%;
    left: 0%;
  }

  .keyword2 {
    top: 76%;
    left: 20%;
  }

  .movie-item:hover .keyword2 {
    top: 80%;
    left: 12%;
  }

  .keyword3 {
    top: 24%;
    left: 80%;
  }

  .movie-item:hover .keyword3 {
    top: 20%;
    left: 88%;
  }

  .keyword4 {
    top: 50%;
    left: 92%;
  }

  .movie-item:hover .keyword4 {
    top: 50%;
    left: 100%;
  }

  .keyword5 {
    top: 76%;
    left: 80%;
  }

  .movie-item:hover .keyword5 {
    top: 80%;
    left: 88%;
  } */

  /* @media (max-width: 480px) {
    .all-keywords {
      display: none;
    }
  } */
</style>
