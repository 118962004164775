<script>
  import { _, locale } from "svelte-i18n";
  import { startup_state, call_collapse_inspirations, call_get_random_prompt } from "$lib/store.js";
  // import { startup_state } from "$lib/store.js";
  import { onMount } from "svelte";
  import Keyword from "$lib/Keyword.svelte";
  import { tick } from "svelte";

  let inspirations;
  let inspirationKeywords;
  let claim;
  let hideClaim;
  let activeCategory = null;
  let categories = [];
  let expandedCategories = new Set();
  let categoriesList;
  let smartDevice =
    /Mobi|Android|iPhone/i.test(navigator.userAgent) || /iPad|Tablet|Android(?!.*Mobile)/i.test(navigator.userAgent);

  if (!smartDevice) {
    categoriesList = ["Mood", "Style", "Genre"];
  } else {
    categoriesList = ["Mood"];
  }

  call_collapse_inspirations.subscribe((action) => {
    if (action && activeCategory) {
      collapseInspirations();
    }
    call_collapse_inspirations.set(false);
  });

  locale.subscribe(async () => {
    categories = await createCategories();
  });

  startup_state.subscribe(() => {
    if (claim && $startup_state == false) {
      // delete claim with a smooth height transition
      claim.style.height = `${claim.scrollHeight}px`;
      setTimeout(() => {
        claim.style.height = 0;
        setTimeout(() => {
          hideClaim = true;
        }, 500);
      }, 0);
    }
    setTimeout(() => {
      if (inspirations) {
        inspirations.style.maxHeight = `${inspirations.scrollHeight}px`; // in case it was set to 'auto' to set starting point for animated height transition
      }
    }, 0);
  });

  async function createCategories() {
    let localeKeys;
    try {
      const json = await import(`./lang/${$locale}.json`);
      localeKeys = json.default;
    } catch (error) {
      console.error(`Failed to load translations for ${locale}:`, error);
      localeKeys = {};
    }
    return categoriesList.map((key) => {
      const keysForCategory = Object.keys(localeKeys).filter((jsonKey) => jsonKey.startsWith(`${key.toLowerCase()}`));

      const tags = keysForCategory.map((jsonKey) => ({
        category: key,
        displayText: $_(jsonKey), // Translate the display text
        id: jsonKey,
        incl_excl: "",
        type: "AD_HOC"
      }));
      return { name: key, keywords: tags };
    });
  }

  const expandInspirations = (category) => {
    activeCategory = category;
    tick().then(() => {
      inspirationKeywords.style.paddingBottom = "22px";
      inspirationKeywords.style.paddingTop = "22px";
      setKeywordsHeight(category);
      inspirationKeywords.style.maxHeight = `${inspirationKeywords.scrollHeight + 44}px`;
      inspirations.style.maxHeight = `${inspirations.scrollHeight + inspirationKeywords.scrollHeight + 44}px`;
    });
  };

  const collapseInspirations = () => {
    activeCategory = null;
    inspirationKeywords.style.maxHeight = "0px";
    inspirationKeywords.style.paddingBottom = "0px";
    inspirationKeywords.style.paddingTop = "0px";
  };

  const toggleInspirations = (category) => {
    if (activeCategory == category) {
      collapseInspirations();
      window._paq.push(["trackEvent", "inspiration " + category, "closed"]);
    } else {
      expandInspirations(category);
      window._paq.push(["trackEvent", "inspiration " + category, "opened"]);
    }
  };

  function toggleCategory(name) {
    const updatedSet = new Set(expandedCategories);
    if (updatedSet.has(name)) {
      updatedSet.delete(name);
    } else {
      updatedSet.add(name);
    }
    expandedCategories = updatedSet; // Reassign so Svelte detects the change
    setKeywordsHeight(name);
  }

  function setKeywordsHeight(name) {
    const keywords = document.getElementById(name);
    inspirationKeywords.style.height = `${inspirationKeywords.scrollHeight + 44}px`;
    tick().then(() => {
      inspirationKeywords.style.height = `${keywords.scrollHeight + 44}px`;
      inspirationKeywords.style.maxHeight = `${keywords.scrollHeight + 44}px`;
      inspirations.style.maxHeight = `${inspirations.scrollHeight + keywords.scrollHeight + 44}px`;
      setTimeout(() => {
        inspirationKeywords.style.height = ``;
      }, 250);
      window.scrollTo({ top: 0, behavior: "smooth" });
    });
  }

  function adjustinspirationsHeight() {
    if (inspirations.style.maxHeight != "0px") {
      if (inspirationKeywords.style.maxHeight != "0px" || inspirationKeywords.style.maxHeight != null) {
        inspirationKeywords.style.maxHeight = `${inspirationKeywords.scrollHeight + 44}px`;
      }
      inspirations.style.maxHeight = `${inspirations.scrollHeight + 44}px`;
    }
  }

  onMount(async () => {
    inspirations = document.querySelector("#inspirations");
    inspirationKeywords = document.querySelector("#inspiration-keywords");
    claim = document.querySelector("#claim");

    window.addEventListener("resize", adjustinspirationsHeight);
    categories = await createCategories();

    return () => {
      window.removeEventListener("resize", adjustinspirationsHeight);
    };
  });
</script>

<!-- ---------------------------------------------------------------------- -->

<div id="inspirations">
  <div id="inspiration-keywords">
    {#if activeCategory}
      {#each categories as category (category.name)}
        {#if category.name === activeCategory}
          <div class="keywords" id={category.name}>
            {#each expandedCategories.has(category.name) ? category.keywords : category.keywords.slice(0, 8) as keyword (keyword.id)}
              <Keyword {keyword} showChevron={true} />
            {/each}

            {#if category.keywords.length > 8}
              <button class="expanding-button" on:click={() => toggleCategory(category.name)}>
                <div class={expandedCategories.has(category.name) ? "collapse" : "expand"} />
              </button>
            {/if}
          </div>
        {/if}
      {/each}
    {/if}
  </div>
  {#each categories as category (category.name)}
    <button
      class="inspiration-keyword-button {activeCategory == category.name ? 'selected' : ''}"
      on:click={() => toggleInspirations(category.name)}>
      <img src="/icons/icon_{category.name.toLowerCase()}.svg" alt={category.name} />
      {$_("search_by") + $_(category.name)}
    </button>
  {/each}
  <button
    class="inspiration-keyword-button random"
    on:click|preventDefault={() => call_get_random_prompt.set(true)}
    on:keypress|preventDefault={() => call_get_random_prompt.set(true)}>
    <img src="/buttons/btn_random_search.svg" alt="random" />
    {$_("random_search")}
  </button>

  {#if !hideClaim}
    <div id="claim">
      <h1>{$_("claim")}</h1>
    </div>
  {/if}
  <div id="spacer" />
</div>

<!-- ---------------------------------------------------------------------- -->

<style>
  #inspirations {
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    align-content: start;
    position: relative;
    width: 94%;
    margin-top: -1px;
    margin-left: 28px;
    margin-right: 28px;
    margin-bottom: 0px;
    box-sizing: border-box;
    overflow: hidden;
    flex-shrink: 0;
    transition: all 0.25s ease-in-out;
  }
  #inspiration-keywords {
    display: flex;
    flex-flow: row wrap;
    align-content: flex-start;
    overflow: hidden;
    width: 100%;
    background: var(--white-10);
    margin-top: 0px;
    margin-bottom: 0px;
    padding-left: 4px;
    padding-right: 4px;
    border-radius: 0 0 24px 24px;
    box-sizing: border-box;
    transition: all 0.25s ease-in-out 0s;
  }
  .keywords {
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    align-content: flex-start;

    /* display: grid;
    grid-template-columns: repeat(auto-fit, minmax(80px, 1fr)); 
    transition: grid-template-rows 0.5s ease-in-out; */

    width: 100%;
    transition: all 0.25s ease-in-out;
  }
  .inspiration-keyword-button {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: var(--white-50);
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    margin: 0.5rem 0.5rem;
    border-top: 1px solid transparent;
    border-left: 1px solid var(--white-30);
    border-right: 1px solid var(--white-30);
    border-bottom: 1px solid var(--white-30);
    border-radius: 0.5rem;
    font-size: 1rem;
    gap: 2px;
    height: 38px;
    text-align: center;
    word-wrap: break-word;
    inline-size: max-content;
    hyphens: auto;
    border: 1px solid var(--white-30);
    background: transparent;
    transition: top 0.25s;
  }
  .inspiration-keyword-button.random {
    color: var(--mowizz-logo-blue);
    border-color: var(--mowizz-logo-blue);
  }
  .inspiration-keyword-button.random img {
    filter: opacity(100%);
    filter: brightness(0) saturate(100%) invert(82%) sepia(18%) saturate(7210%) hue-rotate(173deg) brightness(102%)
      contrast(101%);
  }
  .inspiration-keyword-button.selected {
    background: var(--white-10);
    color: var(--white);
  }
  .inspiration-keyword-button.selected img {
    filter: opacity(100%);
  }
  .inspiration-keyword-button:hover {
    color: var(--white);
    border-left: 1px solid var(--white);
    border-right: 1px solid var(--white);
    border-bottom: 1px solid var(--white);
    border: 1px solid var(--white);
  }
  /* .chevron {
    background-image: url("/icons/icon_chevron.svg");
    background-position: center;
    background-repeat: no-repeat;
    width: 18px;
    height: 18px;
    margin-left: -6px;
    filter: opacity(50%);
  }
  .chevron.open {
    transform: rotate(180deg);
  }
  .inspiration-keyword-button:hover > .chevron {
    filter: opacity(100%);
  } */
  .inspiration-keyword-button img {
    filter: opacity(50%);
    width: 18px;
    height: 18px;
    margin-right: 4px;
  }
  .inspiration-keyword-button:hover img {
    filter: opacity(100%);
  }
  .expanding-button {
    display: flex;
    width: 36px;
    height: 36px;
    background-color: var(--white-90);
    border: solid 1px var(--solid-grey);
    border-radius: 18px;
    cursor: pointer;
    margin-top: 2px;
    margin-left: 0.25rem;
    align-items: center;
  }
  .expanding-button:hover {
    border: solid 1px var(--dark-grey);
  }
  .expanding-button .expand {
    background: url(/icons/icon_dots.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 22px;
    width: 24px;
    height: 24px;
    filter: brightness(0) saturate(100%) invert(48%) sepia(8%) saturate(18%) hue-rotate(46deg) brightness(98%)
      contrast(83%);
  }
  .expanding-button .expand:hover {
    filter: brightness(0);
  }
  .expanding-button .collapse {
    background: url(/icons/icon_chevron.svg) no-repeat;
    transform: rotate(-180deg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 20px;
    width: 24px;
    height: 24px;
    margin-left: -1px;
    filter: brightness(0) saturate(100%) invert(48%) sepia(8%) saturate(18%) hue-rotate(46deg) brightness(98%)
      contrast(83%);
  }
  .expanding-button .collapse:hover {
    filter: brightness(0);
  }
  #claim {
    display: flex;
    width: 100%;
    overflow: hidden;
    justify-content: center;
    box-sizing: border-box;
    transition: all 0.25s ease;
  }
  h1 {
    font-weight: 600;
    color: var(--brightest-red);
    text-align: center;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 30px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    font-style: uppercase;
    box-sizing: border-box;
  }
  #spacer {
    padding-bottom: 10px;
    width: 100%;
    box-sizing: border-box;
  }

  @media (max-width: 480px) {
    /*  .inspiration-keyword {
      margin-bottom: 8px;
      margin-left: 0px;
      margin-right: 0px;
      width: 96%;
      white-space: nowrap; }*/
  }
</style>
