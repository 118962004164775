<script>
  import { _ } from "svelte-i18n";
  import MultiSelect from "svelte-multiselect";
  import { language_options } from "$lib/language-options.js";
  import {
    settings_store,
    initialSettings,
    startup_state,
    call_collapse_filters,
    selected_streamers,
    all_streamers
  } from "$lib/store.js";
  import Slider from "$lib/slider/Slider.svelte";
  import Select from "svelte-select";
  import { getTitles } from "$lib/search.js";
  import { get } from "svelte/store";
  import { onMount } from "svelte";
  import Streamers from "$lib/Streamers.svelte";

  let disabled = false;
  let old_setting_values = JSON.stringify(get(settings_store));

  let openFilter = null;

  let showFilters;
  let filtersComponent;
  let filtersComponentHeight;
  let filters;
  let multiselectRef1;
  let multiselectRef2;

  $: settingYearIsDefault =
    $settings_store.production_period.length === initialSettings.production_period.length &&
    $settings_store.production_period.every((value, index) => value === initialSettings.production_period[index]);
  $: settingLanguageAudioIsDefault =
    $settings_store.audio_languages.length === initialSettings.audio_languages.length &&
    $settings_store.audio_languages.every((value, index) => value === initialSettings.audio_languages[index]);
  $: settingLanguageSubtitlesIsDefault =
    $settings_store.subtitle_languages.length === initialSettings.subtitle_languages.length &&
    $settings_store.subtitle_languages.every((value, index) => value === initialSettings.subtitle_languages[index]);
  $: settingRegionIsDefault = $settings_store.region === initialSettings.region;
  $: settingsDefault =
    $selected_streamers.length == $all_streamers.length &&
    settingYearIsDefault &&
    settingLanguageAudioIsDefault &&
    settingLanguageSubtitlesIsDefault &&
    settingRegionIsDefault;

  settings_store.subscribe(() => {
    checkSettingValues();
  });

  call_collapse_filters.subscribe((action) => {
    if (action && showFilters) {
      toggleFilters();
    }
    call_collapse_filters.set(false);
  });

  function reset(settingProperty) {
    $settings_store[settingProperty] = initialSettings[settingProperty];
  }

  function toggleFilters() {
    showFilters = !showFilters;
    if (showFilters) {
      if ($startup_state) {
        startup_state.set(false);
      }
      window._paq.push(["trackEvent", "filters-icon", "clicked"]);
    }
  }

  function handleClickOutside(event) {
    setTimeout(() => {
      const filterRef = document.getElementById(openFilter);
      if (
        openFilter !== null &&
        filterRef !== event.target &&
        filterRef.parentNode !== event.target.parentNode && // don't trigger, when the button belongs to the filter
        !filterRef.contains(event.target)
      ) {
        checkSettingValues();
        openFilter = null;
      } else if (openFilter && filterRef.parentNode === event.target.parentNode) {
        checkSettingValues();
      }
    }, 10);
  }

  function checkSettingValues() {
    const new_values = JSON.stringify($settings_store);
    if (old_setting_values !== new_values) {
      old_setting_values = new_values;
      getTitles();
    }
  }

  function updatefiltersComponentHeight() {
    // calculate the filtersComponent height based on the height of all containing divs
    filtersComponentHeight =
      Array.from(filtersComponent.children).reduce((total, div) => total + div.offsetHeight + 0, 0) + "px";
  }

  onMount(() => {
    // Multiselect workaround to avoid text cursor on input field
    // searchElements = document.querySelector("#search-elements");
    const inputFields1 = multiselectRef1?.querySelectorAll("input");
    inputFields1[1].setAttribute("readonly", "true");
    const inputFields2 = multiselectRef2?.querySelectorAll("input");
    inputFields2[1].setAttribute("readonly", "true");
    window.addEventListener("pointerdown", handleClickOutside);
    const observer = new ResizeObserver(updatefiltersComponentHeight);
    if (filters) observer.observe(filters);

    return () => {
      window.removeEventListener("pointerdown", handleClickOutside);
      observer.disconnect();
    };
  });
</script>

<!-- ---------------------------------------------------------------------- -->

<div id="filters-component" bind:this={filtersComponent} style="height: {filtersComponentHeight}">
  <button
    id="filter-button"
    class="{showFilters ? 'open ' : ''}{!settingsDefault ? 'active ' : ''}"
    tabindex="0"
    alt="filters"
    title="filters"
    on:click={toggleFilters}
    on:keydown={toggleFilters}>
    <div class="chevron {showFilters ? 'open' : ''}" />
    <img src="/buttons/btn_filters.svg" alt={$_("Filters")} />
    {$_("Filters")}
  </button>
  <div id="filters" bind:this={filters} class={showFilters ? "open " : ""}>
    <Streamers />
    <div class="filter-blocks">
      <div id="region-settings" class="filter-block {!settingRegionIsDefault ? 'active' : ''}">
        {#if !settingRegionIsDefault}
          <button
            title={$_("reset")}
            class="reset-button"
            on:click={() => reset("region")}
            on:keypress={() => reset("region")} />
        {/if}
        <div class="filter-header">
          <div class="category-icon" style="background-image: url('/icons/icon_location.svg" />
          <h3>{$_("region-setting")}</h3>
        </div>
        <Select
          items={[
            { value: "DE", label: $_("germany") },
            { value: "CH", label: $_("switzerland") },
            { value: "AT", label: $_("austria") }
          ]}
          value={$settings_store.region}
          bind:justValue={$settings_store.region}
          clearable={false}
          searchable={false}
          inputStyles="user-select: none; cursor: pointer; appearance: none;"
          --border="white 1px solid"
          --border-radius="4px"
          --border-color="var(--icon-light-blue)"
          --height="2em"
          --item-color="var(--white)"
          --selected-item-color="var(--white)"
          --item-hover-bg="var(--white-30)"
          --list-background="var(--dark-grey)"
          --item-is-active-bg="var(--solid-blue)"
          --background="var(--dark-grey)" />
      </div>
      <div id="release-year-settings" class="filter-block {!settingYearIsDefault ? 'active' : ''}">
        {#if !settingYearIsDefault}
          <button
            title={$_("reset")}
            class="reset-button"
            on:click={() => reset("production_period")}
            on:keypress={() => reset("production_period")} />
        {/if}
        <div class="filter-header">
          <div class="category-icon" style="background-image: url('/icons/icon_release-year.svg" />
          <h3>{$_("production-year")}</h3>
        </div>
        {$settings_store.production_period[0]} - {$settings_store.production_period[1]}
        <Slider
          min={initialSettings.production_period[0]}
          max={initialSettings.production_period[1]}
          bind:value={$settings_store.production_period} />
      </div>

      <div id="languages-audio-settings" class="filter-block {!settingLanguageAudioIsDefault ? 'active' : ''}">
        {#if !settingLanguageAudioIsDefault}
          <button
            title={$_("reset")}
            class="reset-button"
            on:pointerdown={() => {
              reset("audio_languages");
            }}
            on:keypress={() => reset("audio_languages")} />
        {/if}
        <div class="filter-header">
          <div class="category-icon" style="background-image: url('/icons/icon_streaminglink_audio-language.svg" />
          <h3>{$_("audio-languages")}:</h3>
        </div>
        <span>({$_("none-means-any")})</span>

        <div class="languages" bind:this={multiselectRef1}>
          <MultiSelect
            options={language_options}
            {disabled}
            bind:selected={$settings_store.audio_languages}
            inputmode={"none"}
            inputStyle="user-select: none; cursor: pointer; appearance: none;" />
        </div>
      </div>

      <div id="languages-subtitles-settings" class="filter-block {!settingLanguageSubtitlesIsDefault ? 'active' : ''}">
        {#if !settingLanguageSubtitlesIsDefault}
          <button
            title={$_("reset")}
            class="reset-button"
            on:pointerdown={() => {
              reset("subtitle_languages");
            }}
            on:keypress={() => reset("audio_languages")} />
        {/if}
        <div class="filter-header">
          <div class="category-icon" style="background-image: url('/icons/icon_streaminglink_subtitle-language.svg" />
          <h3>{$_("subtitle-languages")}:</h3>
        </div>
        <span>({$_("none-means-any")})</span>
        <div class="languages" bind:this={multiselectRef2}>
          <MultiSelect
            options={language_options}
            {disabled}
            bind:selected={$settings_store.subtitle_languages}
            inputStyle="user-select: none; cursor: pointer; appearance: none;"
            inputmode={"none"} />
        </div>
      </div>
    </div>
  </div>
</div>

<!-- ---------------------------------------------------------------------- -->

<style>
  #filters-component {
    width: 94%;
    margin-top: 12px;
    margin-bottom: 24px;
    align-self: center;
    transition: max-height 0.25s;
  }
  #filter-button {
    display: flex;
    background-color: transparent;
    color: var(--white-50);
    font-size: 1rem;
    border: 1px solid var(--white-30);
    border-radius: 8px;
    cursor: pointer;
    height: 48px;
    padding-left: 1rem;
    padding-right: 1rem;
    align-items: center;
    inline-size: max-content;
    margin: 0 auto;
    object-fit: cover; /* Cover the container while preserving aspect ratio */
    object-position: center; /* Center the image within the container */
  }
  #filter-button:hover {
    color: var(--white);
    border: 1px solid var(--white);
  }
  #filter-button.open {
    filter: brightness(1);
    color: var(--white);
    background-color: var(--black-60);
  }
  #filter-button.active {
    background-color: var(--lightest-blue);
    color: var(--black);
  }
  #filter-button.active:hover {
    background-color: var(--lightest-blue);
    border: 1px solid var(--white);
  }
  #filter-button img {
    filter: opacity(50%);
    width: 40px;
    height: 40px;
    margin-left: -0.5rem;
    margin-right: 4px;
  }
  #filter-button:hover img {
    filter: opacity(100%);
  }
  #filter-button.open img {
    filter: opacity(100%);
  }
  #filter-button.active img {
    filter: opacity(100%) brightness(0.1);
  }
  #filters {
    display: flex;
    flex-wrap: wrap;
    overflow: visible;
    justify-content: center;
    background-color: var(--black-60);
    border-radius: 12px;
    position: relative;
    margin-top: 6px;
    margin-left: auto;
    margin-right: auto;
    align-self: center;
    gap: 6px;
    align-items: center;
    opacity: 0;
    transform: translateY(-20%);
    max-height: 0;
    width: 100%;
    transition:
      opacity 0.5s ease,
      transform 0.5s ease,
      max-height 0.25s ease;
  }
  #filters.open {
    opacity: 1;
    padding-bottom: 12px;
    transform: translateY(0);
    max-height: 2000px; /* A large enough height to fit content */
    transition:
      opacity 0.3s ease,
      transform 0.3s ease;
  }
  .chevron {
    display: none;
    background-image: url("/icons/icon_chevron.svg");
    background-position: center;
    background-repeat: no-repeat;
    width: 18px;
    height: 18px;
    flex-shrink: 0;
    margin-left: -6px;
    /* filter: opacity(50%); */
  }
  .chevron.open {
    display: block;
    transform: rotate(180deg);
  }
  #filter-button.active > .chevron.open {
    filter: brightness(0.1);
  }
  .filter-blocks {
    display: grid;
    gap: 12px;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    width: 94%;
    margin-top: 12px;
  }
  .filter-block {
    border-radius: 8px;
    border: 1px solid transparent;
    background-color: var(--dark-grey-60);
    color: var(--white);
    top: 100%;
    left: -50%;
    padding: 1em;
  }
  .filter-block.active {
    border: 1px solid var(--lightest-blue);
  }
  .filter-header {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    margin-top: 0px;
  }
  .category-icon {
    filter: brightness(0) saturate(100%) invert(75%) sepia(63%) saturate(1862%) hue-rotate(172deg) brightness(100%)
      contrast(101%);
    background-repeat: no-repeat;
    width: 18px;
    height: 18px;
    margin-right: 8px;
  }
  h3 {
    font-size: 1rem;
    color: var(--lightest-blue);
    margin-bottom: 6px;
    margin-top: 2px;
  }
  #release-year-settings {
    --thumb-bg: var(--solid-blue);
    --progress-bg: var(--solid-blue);
    --track-bg: var(--solid-grey);
  }
  .filter-block:focus {
    border: 1px solid red;
  }
  .languages {
    margin-top: 8px;
    margin-bottom: 8px;
    /* see: https://github.com/janosh/svelte-multiselect#--styling */
    margin-bottom: 2em;
    --sms-text-color: var(--white);
    --sms-options-bg: var(--dark-grey);
    --sms-active-color: var(--white-50);
    --sms-selected-bg: var(--solid-blue);
    --sms-selected-li-padding: 4pt 8pt;
    cursor: pointer;
  }
  #region-settings {
    padding: 24 px;
  }
  .reset-button {
    background: url(/buttons/btn_reset.svg) no-repeat;
    background-size: 40px 40px;
    width: 40px;
    height: 40px;
    border: none;
    position: relative;
    float: right;
    margin-top: -12px;
    margin-right: -4px;
    margin-bottom: 4px;
    cursor: pointer;
  }
  .reset-button:hover {
    background: url(/buttons/btn_reset_hover.svg) no-repeat;
    background-size: 40px 40px;
  }

  /* Make the slider thumb bigger:
  Observe that the slider is an inactive third party component (https://github.com/doklab/mowizz-search/issues/186) 
  we are going to adopt the slider code, then we have all freedom.
  
  There is also an option to entirely define the 'slot' ourselves, but using the :global construct is simpler.
  see also: https://github.com/BulatDashiev/svelte-slider
  */
  #release-year-settings :global(.thumb) {
    width: 24px;
    height: 24px;
  }
  /* @media (max-width: 480px) { */
  /* #filters {
    display: none;
  } */
  /* #filter-button {
    display: flex;
    margin: 0 auto auto;
  } */
  /* } */
</style>
