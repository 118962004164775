<!-- Documentation link: https://kit.svelte.dev/docs/routing#page-page-svelte -->

<script>
  import { _, locale } from "svelte-i18n";
  import { pushState } from "$app/navigation";
  import Search from "$lib/Search.svelte";
  import MovieList from "$lib/MovieList.svelte";
  import MovieDetail from "$lib/MovieDetail.svelte";
  import Blog from "$lib/Blog.svelte";
  import Footer from "$lib/Footer.svelte";
  import {
    startup_state,
    settings_store,
    keywords_store,
    selected_streamers,
    service_name,
    all_streamers
  } from "$lib/store.js";
  import { locale_options } from "$lib/LocaleConstants.js";
  import { language_options } from "$lib/language-options.js";
  import { onMount } from "svelte";

  export let data;
  let titleID;
  let titleSearch;
  let domainName;

  $: if (titleSearch && $all_streamers != "") {
    startup_state.set(false);
    setTitlesParams();
  }

  const randomKey = `sample_prompt${Math.trunc(Math.random() * 70) + 1}`;
  $: prompt = $_(randomKey);

  function closeDetailModal() {
    removeTitleIDfromURL();
    document.body.style.overflow = "";
  }

  function removeTitleIDfromURL() {
    const currentUrl = new URL(window.location.href);
    currentUrl.searchParams.delete("id");
    currentUrl.searchParams.delete("title");
    pushState(currentUrl.pathname + currentUrl.search);
    titleID = false;
  }

  function setFilters() {
    const audio = titleSearch.filters.audio.map((value) => language_options.find((option) => option.value === value));
    const subtitles = titleSearch.filters.subtitles.map((value) =>
      language_options.find((option) => option.value === value)
    );
    settings_store.update((current) => {
      return {
        ...current,
        region: titleSearch.filters.region,
        audio_languages: audio,
        subtitle_languages: subtitles,
        production_period: [titleSearch.filters.minProdYear, titleSearch.filters.maxProdYear]
      };
    });
  }

  async function setTitlesParams() {
    selected_streamers.update(() => {
      return $all_streamers.filter((streamer) => titleSearch.filters.streamers.includes(streamer.id));
    });

    const localeKey = Object.entries(locale_options).find(([, v]) => v === titleSearch.locale)?.[0];
    locale.set(localeKey);

    setFilters();

    keywords_store.update(() => {
      return titleSearch.tags;
    });
  }

  onMount(() => {
    domainName = window.location.hostname;
    if (domainName.includes("trovio")) {
      service_name.set("Trovio");
    } else if (domainName.includes("streamin")) {
      service_name.set("Streamin");
    } else if (domainName.includes("streamothek")) {
      service_name.set("Streamothek");
    } else if (domainName.includes("streamonaut")) {
      service_name.set("Streamonaut");
    }
    const currentUrl = new URL(window.location.href);
    titleID = currentUrl.searchParams.get("id") || false;
    const titleSearchParams = currentUrl.searchParams.get("search") || false;
    if (titleSearchParams) {
      titleSearch = JSON.parse(decodeURIComponent(titleSearchParams));
    }
  });
</script>

<!-- ---------------------------------------------------------------------- -->

{#if !prompt.startsWith("sample_") && $settings_store}
  <Blog />
  {#if titleID}
    <MovieDetail {titleID} isOpen={true} singleMode={true} on:close={closeDetailModal} />
  {/if}
  <div id="search">
    <Search samplePrompt={prompt} cache={data} />
  </div>
  <MovieList />
  <Footer />
{/if}

<!-- ---------------------------------------------------------------------- -->

<style>
  #search {
    top: var(--header-height);
    z-index: 100;
    display: flex;
    justify-content: center;
    margin-bottom: 48px;
    background: linear-gradient(180deg, var(--background) 88%, rgba(0, 0, 0, 0) 100%);
    scroll-snap-align: start;
    transition: top 0.25s ease-in-out;
  }
</style>
